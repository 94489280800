<template>
  <div>
    <v-card class="mx-auto">
      <v-list-item class="primary">
        <v-list-item-content>
          <v-list-item-title class="headline text-left white--text">{{
            title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-text class="headline">
        <v-row>
          <v-col sm="12">
            <v-form
              ref="accountCreationForm"
              @submit.prevent
              lazy-validation
              v-model="valid"
            >
              <!-- NOT PUBLIC -->
              <v-alert
                outlined
                color="blue lighten-2"
                v-if="isAdmin || isInternalUser"
              >
                <v-card class="pa-2" flat>
                  <v-row>
                    <v-col sm="12" md="3">
                      <v-text-field
                        v-model="selectedBusiness.PreviousBusinessID"
                        label="Previous Business ID"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="3" class="mt-2">
                      <v-checkbox
                        label="Is Active"
                        :disabled="!isAdmin"
                        v-model="selectedBusiness.IsActive"
                      ></v-checkbox>
                    </v-col>
                    <v-col sm="12" md="6" class="mt-2">
                      <datePickerWithText
                        :disabled="!isAdmin"
                        label="Inactive Date"
                        v-model="selectedBusiness.InactiveDate"
                      ></datePickerWithText>
                    </v-col>
                  </v-row>
                </v-card>
              </v-alert>
              <v-row>
                <v-col sm="12">
                  <v-text-field
                    v-model="selectedBusiness.BusinessName"
                    label="Business Name"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="selectedBusiness.PhysicalAddress"
                    label="Establishment Physical Address"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="2">
                  <v-text-field
                    v-model="selectedBusiness.PhysicalCity"
                    label="City"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="2">
                  <LuState
                    v-model="selectedBusiness.PhysicalStateID"
                    label="State"
                    :rules="[(v) => !!v || 'required']"
                  ></LuState>
                </v-col>
                <v-col sm="12" md="2">
                  <v-text-field
                    v-model="selectedBusiness.PhysicalZip"
                    label="Zip"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="selectedBusiness.BillingAddress"
                    label="Establishment Billing Address"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="2">
                  <v-text-field
                    v-model="selectedBusiness.BillingCity"
                    label="City"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="2">
                  <LuState
                    v-model="selectedBusiness.BillingStateID"
                    label="State"
                  ></LuState>
                </v-col>
                <v-col sm="12" md="2">
                  <v-text-field
                    v-model="selectedBusiness.BillingZip"
                    label="Zip"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="selectedBusiness.MailingAddress"
                    label="Establishment Mailing Address"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="2">
                  <v-text-field
                    v-model="selectedBusiness.MailingCity"
                    label="City"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="2">
                  <LuState
                    v-model="selectedBusiness.MailingStateID"
                    label="State"
                    :rules="[(v) => !!v || 'required']"
                  ></LuState>
                </v-col>
                <v-col sm="12" md="2">
                  <v-text-field
                    v-model="selectedBusiness.MailingZip"
                    label="Zip"
                    :rules="[(v) => !!v || 'required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="5">
                  <v-text-field
                    v-model="selectedBusiness.Phone"
                    v-mask="phoneMask"
                    label="Phone Number"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="1">
                  <v-text-field
                    v-model="selectedBusiness.PhoneExt"
                    v-mask="extMask"
                    label="Ext."
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="5">
                  <v-text-field
                    v-model="selectedBusiness.AltPhone"
                    v-mask="phoneMask"
                    label="Alternate Number"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="1">
                  <v-text-field
                    v-model="selectedBusiness.AltPhoneExt"
                    v-mask="extMask"
                    label="Ext."
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="selectedBusiness.Email"
                    :rules="emailRules"
                    label="Email Address"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="selectedBusiness.AltEmail"
                    :rules="emailRules"
                    label="Alternate Email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn color="primary" @click="saveBusinessDetails">Save</v-btn>
          <v-btn v-if="editPage" color="primary" @click="$emit('closeDialog')"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Business from '@classes/Business'
import UserBusiness from '@classes/UserBusiness'
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import datePickerWithText from '@components/datePickerWithText'
import LuState from '@components/select/a-single/LuState.vue'
import { mask } from 'vue-the-mask'

export default {
  directives: {
    mask,
  },
  page: {
    title: 'UserAccount',
  },
  components: {
    datePickerWithText,
    LuState,
  },
  model: {
    prop: 'dialog',
    event: 'closeDialog',
  },
  props: {
    title: String,
    dialog: Boolean,
    editPage: Boolean,
    selectedBusiness: Object,
  },
  data() {
    return {
      valid: true,
      //selectedBusiness: new Business(),
      phoneMask: '(###)  ###-####',
      extMask: '#####',
      emailRules: [
        (v) =>
          !v ||
          /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'Valid email format example@email.com',
      ],
    }
  },
  created() {},
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods,
    handleError: call('errors/handleError'),
    ...call('business', [
      'saveBusiness',
      'loadSelectedBusiness',
      'refreshSelectedBusiness',
    ]),
    async validate() {
      this.$refs.accountCreationForm.validate()
    },
    editBusiness(entry) {
      this.selectedBusiness = new Business(entry)
      this.modalText = 'Edit Business'
      this.dialog = !this.dialog
    },
    saveBusinessDetails() {
      this.validate().then(() => {
        if (this.valid) {
          var userBusiness = new UserBusiness({
            UserID: this.currentUser.ID,
            BusinessRoleID: 2,
          })
          this.selectedBusiness.UserBusiness.push(userBusiness)
          this.saveBusiness(this.selectedBusiness.getSaveData()).then(
            (res) => {
              if (res.data && res.data.ID) {
                var businessId = res.data.ID
                this.refreshCurrentUser().then(
                  (res) => {
                    this.$router.push({
                      path: '/userBusiness',
                      params: {
                        businessId: businessId,
                      },
                    })
                  },
                  (err) => {}
                )
              } else {
                this.refreshSelectedBusiness()
                this.$emit('closeDialog')
              }
              // this.selectedBusiness = new Business()
              this.$refs.accountCreationForm.resetValidation()
              // this.$router.push({
              //   name: 'hempApplication',
              // })
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
